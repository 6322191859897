// Globals
$global-font-family:                            'Roboto', sans-serif;
$global-border-color:                           #BCBEC0;
$global-font-color:                             #6D6E71;



//  UIKIT
$base-body-font-family:                          $global-font-family;
$base-hr-border:                                 $global-border-color;
$base-body-color:                                $global-font-color;

$utility-container-max-width:                    800px;
$utility-container-padding-horizontal:           10px;
$utility-container-large-max-width:              800px;